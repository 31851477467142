<template>
  <span v-if="arr.length">
    <template v-if="mode === 'strings'"> {{ csv }} </template>
    <span v-for="(link, key) in csv" v-else :key="key">
      <smart-link :to="link?.uri">{{ link?.title }}</smart-link>
      <template v-if="key !== csv.length - 1">
        <span>, </span>
      </template>
    </span>
    <template v-if="more > 0">
      <v-btn
        v-if="state === 'more'"
        variant="text"
        color="linkColor"
        class="text-lowercase font-weight-regular see-more"
        @click="state = 'less'"
      >
        + {{ more }} more
      </v-btn>
      <v-btn
        v-else
        variant="text"
        color="linkColor"
        class="text-lowercase font-weight-regular see-more"
        @click="state = 'more'"
      >
        - less
      </v-btn>
    </template>
  </span>
</template>
<script setup>
const props = defineProps({
  arr: { type: Array, default: () => [] },
  limit: { type: Number, default: 3 },
});
const mode = ref(_.get(props.arr, [0, "title"]) ? "links" : "strings");
const state = ref("more");
const more = props.arr.length - props.limit;

const csv = computed(() => {
  const limited = state.value === "more" ? _.take(props.arr, props.limit) : props.arr;
  return mode.value === "strings" ? limited.join(", ") : limited;
});
</script>
